@import "../global/css/colors";
@import "../global/css/fonts";
@import "../global/css/gaps";

.contact {
	min-height: 100vh;

	.menu-section {
		justify-self: center;
		align-self: end;
		margin: 0 0 30px 0;
	}

	.bg-plane {
		background: url('../global/img/plane.svg') no-repeat center center;
		background-size: 100% 100%;
		position: absolute;
		left: -70px;
		top: 235px;
		z-index: -1;
		width: 600px;
		height: 400px;
	}

	.page-name {
		@extend %h4-header-text;
		color: $color-pri;
		position: absolute;
		right: 0;
		margin: 25px 60px 0 0;
		z-index: 1;
		text-transform: capitalize;
	}

	.contact-section {
		margin: 20vh 0 0 0;

		.contact-content {
			@extend %paragraph-big-text;
			color: $color-grey-900;
			margin: 0 0 30px 0;
		}

		.form-wrapper {

		}
	}
}

@media (max-width: $tablet-screen) {
	.contact {

		.page-name {
			@include h5-header-text;
			margin: 10px 25px 0 0;
		}

		.bg-plane {
			width: 400px;
			height: 250px;
		}

		.contact-section {
			padding: 35px 25px;

			.contact-content {
				@include paragraph-normal-text;
			}
		}
	}
}

@media (max-width: $phone-screen) {
	.contact {

		.bg-plane {
			width: 300px;
			height: 200px;
		}

		.contact-section {
			margin: 10vh 0 0 0;
		}
	}
}

/* Animations */

.contact {
  .bg-plane {
    transform: translateY(20%);
    opacity: 0;
    transition: all .3s;
  }
  .contact-section {
    transform: translateY(20%);
    opacity: 0;
    transition: all .3s;
  }
}

.pr-appear-done, .pl-appear-done,
.pr-enter-done, .pl-enter-done,
.pr-exit-active, .pl-exit-active, {

  .contact {
    .bg-plane {
      transform: translateY(0);
      opacity: 1;
    }
    .contact-section {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.pr-enter-active, .pl-enter-active {

  .contact {
    .bg-plane {
      transform: translateY(0);
      opacity: 1;
      transition-delay: .3s;
    }
    .contact-section {
      transform: translateY(0);
      opacity: 1;
      transition-delay: .5s;
    }
  }
}
