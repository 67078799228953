@import "../global/css/colors";
@import "../global/css/fonts";
@import "../global/css/gaps";

$image-width: 100%;
$img-con-pd: 40px;
$about-title-width: 100%;
$nav-mr: $img-con-pd - $item-pd-v;

.about {

  .menu-section {
    align-self: end;
    margin: 0 0 $nav-mr $nav-mr;
    z-index: 1;
  }

  .page-name {
    @extend %h4-header-text;
    color: $color-pri;
    position: absolute;
    right: 0;
    margin: 25px 60px 0 0;
    z-index: 1;
    text-transform: capitalize;
  }

  .about-section {
    color: $color-grey-900;

    .about-title {
      @extend %h3-header-text;
      transform-origin: top left;
      transform: rotate(90deg);
      margin: 0 0 270px 8px;
      line-height: 1em;
      text-transform: uppercase;
      align-self: flex-end;
      width: max-content;
    }

    .about-image {
      width: $image-width;
      height: 100vh;
      opacity: 0.85;
      z-index: -1;
      object-fit: cover;
    }

    .about-content {
      @extend %paragraph-big-text;
      margin: 190px 0 0 0;
      text-align: right;
    }
  }
}

@media (max-width: $tablet-screen) {
  .about {

    .menu-section {
      margin: $nav-mr 0;
      justify-self: center;
    }

    .page-name {
      @include h5-header-text;
      margin: 10px 25px 0 0;
    }

    .about-section {

      .about-title {
        @include h3-header-mobile-text;
        writing-mode: sideways-lr;
        margin: 0 0 #{$img-con-pd - 15px} 0;
        justify-self: center;
        color: $color-white-85;
        transform: none;
      }

      .about-image {
        height: 90vh;
      }

      .about-content {
        @include paragraph-normal-text;
        text-align: left;
        background: #FFF;
        padding: 35px 25px;
        margin: 0;
      }
    }
  }
}

@media (max-width: $phone-screen) {
  .about {

    .page-name {
      color: $color-white-85;
    }
  }
}

/* Animations */

.about {
  .about-section {
    .about-image {
      transform: translateY(20%);
      opacity: 0;
      transition: all .3s;
    }

    .about-content {
      transform: translateY(20%);
      opacity: 0;
      transition: all .3s;
    }
  }
}

.pr-appear-done, .pl-appear-done,
.pr-enter-done, .pl-enter-done,
.pr-exit-active, .pl-exit-active, {

  .about {
    .about-section {
      .about-image {
        transform: translateY(0);
        opacity: 1;
      }

      .about-content {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.pr-enter-active, .pl-enter-active {

  .about {
    .about-section {
      .about-image {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .3s;
      }

      .about-content {
        transform: translateY(0);
        opacity: 1;
        transition-delay: .5s;
      }
      }
  }
}
