$base-font-size: 16px;

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  font-size: $base-font-size;
}

#root {
  position: relative;
  overflow: hidden;
}