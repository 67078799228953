// Fonts

// Gothic A1
@font-face {
    font-family: 'Gothic A1';
    src: url('../font/GothicA1/GothicA1-Black.woff2') format('woff2'),
        url('../font/GothicA1/GothicA1-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Gothic A1';
    src: url('../font/GothicA1/GothicA1-Bold.woff2') format('woff2'),
        url('../font/GothicA1/GothicA1-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Gothic A1';
    src: url('../font/GothicA1/GothicA1-SemiBold.woff2') format('woff2'),
        url('../font/GothicA1/GothicA1-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Gothic A1';
    src: url('../font/GothicA1/GothicA1-Regular.woff2') format('woff2'),
        url('../font/GothicA1/GothicA1-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gothic A1';
    src: url('../font/GothicA1/GothicA1-ExtraLight.woff2') format('woff2'),
        url('../font/GothicA1/GothicA1-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

// Muli
@font-face {
    font-family: 'Muli';
    src: url('../font/Muli/Muli-Regular.woff2') format('woff2'),
        url('../font/Muli/Muli-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Muli';
    src: url('../font/Muli/Muli-Light.woff2') format('woff2'),
        url('../font/Muli/Muli-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

// Font variables
%font-menu-text {
  font-family: Gothic A1;
  font-style: normal;
  font-weight: 900;
  font-size: 1em;
  line-height: 20px;
}
%button-text {
	font-family: Gothic A1;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
}
%h3-header-text {
	font-family: Gothic A1;
	font-style: normal;
	font-weight: 600;
	font-size: 37.9px;
	line-height: 47px;
	letter-spacing: 0.02em;
}
%h3-header-mobile-text {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: 600;
    font-size: 28.43px;
    line-height: 36px;
}
@mixin h3-header-mobile-text {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: 600;
    font-size: 28.43px;
    line-height: 36px;
}
%h4-header-text {
	font-family: Gothic A1;
	font-style: normal;
	font-weight: 200;
	font-size: 28.43px;
	line-height: 36px;
    letter-spacing: 0.01em;
}
%h5-header-text {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: 200;
    font-size: 21.33px;
    line-height: 27px;
    letter-spacing: 0.01em;
}
@mixin h5-header-text {
    font-family: Gothic A1;
    font-style: normal;
    font-weight: 200;
    font-size: 21.33px;
    line-height: 27px;
    letter-spacing: 0.01em;
}
%paragraph-big-text {
	font-family: Muli;
	font-style: normal;
	font-weight: 300;
	font-size: 21.33px;
	line-height: 27px;
	letter-spacing: 0.01em;
}
%paragraph-normal-text {
	font-family: Muli;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.01em;
}
@mixin paragraph-normal-text {
    font-family: Muli;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
}
%form-label-text {
	font-family: Muli;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
}