@import "../global/css/colors";
@import "../global/css/fonts";
@import "../global/css/gaps";

.contact-form {
	display: flex;
	flex-direction: column;

	.horizantal {
		display: flex;

		.form-group {
			width: 100%;

			&:nth-child(1) {
				margin-right: 5px;
			}
			&:nth-child(2) {
				margin-left: 5px;
				text-align: left;
			}
		}
	}

	.form-group {
		margin: 15px 0;

		label {
			@extend %form-label-text;
			color: $color-grey-500;
		}

		input {
			@extend %paragraph-normal-text;
			box-sizing: border-box;
			width: 100%;
			padding: 5px;
			margin-top: 10px;
			border: none;
			background: none;
			border-bottom: 2px solid $color-grey-900;
			color: $color-grey-900;
		}

		input[type="submit"] {
			@extend %button-text;
			cursor: pointer;
			text-transform: uppercase;
			width: auto;
			padding: 3px;

			&:hover {
				color: $color-grey-900;
			}
		}

		textarea {
			@extend %paragraph-normal-text;
			box-sizing: border-box;
			width: 100%;
			padding: 5px;
			margin-top: 10px;
			border: none;
			background: none;
			border-bottom: 2px solid $color-grey-900;
			color: $color-grey-900;
			resize: none;
			overflow: hidden;
		}

		&:nth-last-child(1) {
			text-align: right;
		}
	}
}
