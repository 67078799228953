@import "../global/css/colors";
@import "../global/css/fonts";
@import "../global/css/gaps";

$image-width: 100%;
$img-con-pd: 40px;
$work-title-width: 100%;
$nav-mr: 40px - $item-pd-v;
$content-mr: 190px;
$ctrl-btn-sz: 45px;

.works {

	.menu-section {
		align-self: end;
		margin: 0 0 $nav-mr ($img-con-pd - $item-pd-h);
		z-index: 1;
	}

	.page-name {
		@extend %h4-header-text;
		color: $color-pri;
		position: absolute;
		right: 0;
		margin: 25px 60px 0 0;
		z-index: 1;
		text-transform: capitalize;
	}

	.control-section {
		display: flex;
		justify-content: flex-end;
	}
}

.work-item {
	color: $color-grey-900;

	.work-title {
		@extend %h3-header-text;
		writing-mode: sideways-lr;
		margin: 0 #{-$column-gap} $img-con-pd 0;
		line-height: 1em;
		text-transform: uppercase;
		justify-self: flex-end;
	}

	.work-image {
		width: $image-width;
		height: 100vh;
		opacity: 0.85;
		z-index: -1;
		object-fit: cover;
	}

	.vertical {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.work-content {
		@extend %paragraph-big-text;
		margin: $content-mr 0 0 0;
	}

	.work-go-button {
		@extend %button-text;
		text-align: center;
		text-transform: uppercase;
		box-sizing: border-box;
		cursor: pointer;
		padding: 3px;
		margin-top: 70px;
		border: none;
		background: none;
		border-bottom: 2px solid $color-grey-900;
		color: $color-grey-900;
	}
}

.control-buttons {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-transform: uppercase;
	margin: #{$content-mr - $ctrl-btn-sz} 0 0 0;

	.prev {
		@extend %button-text;
		writing-mode: sideways-lr;
		border: none;
		padding: 0;
		width: $ctrl-btn-sz;
		height: $ctrl-btn-sz;
		background: url('../global/img/prev.svg') no-repeat right;
		background-size: contain;
		color: $color-grey-900;
		cursor: pointer;
	}
	.next {
		@extend %button-text;
		writing-mode: sideways-rl;
		border: none;
		padding: 0;
		width: $ctrl-btn-sz;
		height: $ctrl-btn-sz;
		background: url('../global/img/next.svg') no-repeat left;
		background-size: contain;
		color: $color-grey-900;
		cursor: pointer;
		margin-top: 350px;
	}
}

@media (max-width: $tablet-screen) {
	.works {

		.menu-section {
			margin: $nav-mr 0;
			justify-self: center;
		}

		.page-name {
			@include h5-header-text;
			margin: 10px 25px 0 0;
		}
	}

	.work-item {

		.work-title {
			@include h3-header-mobile-text;
			margin: 0 0 #{$img-con-pd - 15px} 0;
			justify-self: center;
			color: $color-white-85;
		}

		.work-image {
			height: 90vh;
		}

		.work-content {
			@include paragraph-normal-text;
			background: #FFF;
			padding: 35px 25px;
			margin: 0;
		}

		.work-go-button {
			margin: 35px 0;
			z-index: 1;
		}
	}

	.control-buttons {
		height: calc(90vh - #{$img-con-pd} + 15px);
		margin: 0;
		justify-content: flex-end;

		.prev {
			color: $color-white-85;
			background-image: url('../global/img/prev-white.svg');
		}

		.next {
			color: $color-white-85;
			background-image: url('../global/img/next-white.svg');
			margin-top: 65vh;
		}
	}
}

@media (max-width: $phone-screen) {
	.works {

		.page-name {
			color: $color-white-85;
		}
	}
}

/* Animations */

.work-item {

  .work-image {
    transform: translateY(20%);
    opacity: 0;
    transition: all .3s;
  }

  .vertical {

    > * {
      transform: translateY(20%);
      opacity: 0;
      transition: all .3s;
    }
  }
}

.pr-appear-done, .pl-appear-done,
.pr-enter-done, .pl-enter-done,
.pr-exit-active, .pl-exit-active, {

  .work-item {

    .work-image {
      transform: translateY(0);
      opacity: 1;
    }

    .vertical {
      > * {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.pr-enter-active, .pl-enter-active {

  .work-item {

    .work-image {
      transform: translateY(0);
      opacity: 1;
      transition-delay: .3s;
    }

    .vertical {
      > * {
        transform: translateY(0);
        opacity: 1;

        @for $i from 1 through 2 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 0.4s;
          }
        }
      }
    }
  }
}
