@import "../global/css/colors";
@import "../global/css/fonts";
@import "../global/css/gaps";

$cube-size: 200px;

.page-name {
  @extend %h4-header-text;
  color: $color-pri;
  margin: 25px 60px 0 0;
  z-index: 1;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
}

.scene {
  width: $cube-size;
  height: $cube-size;
  perspective: #{$cube-size * 2};
  position: absolute;
  right: 0;
  z-index: 5;

  .cube {
    width: $cube-size;
    height: $cube-size;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-#{$cube-size / 2});
    transition: transform 1s;

    &.show {

      &--front {
        transform: translateZ(-#{$cube-size / 2}) rotateY(0deg);

        .cube-face--front {
          opacity: 1;
        }
      }
      &--back {
        transform: translateZ(-#{$cube-size / 2}) rotateY(-180deg);

        .cube-face--back {
          opacity: 1;
        }
      }
      &--right {
        transform: translateZ(-#{$cube-size / 2}) rotateY(-90deg);

        .cube-face--right {
          opacity: 1;
        }
      }
      &--left {
        transform: translateZ(-#{$cube-size / 2}) rotateY(90deg);

        .cube-face--left {
          opacity: 1;
        }
      }
      &--top {
        transform: translateZ(-#{$cube-size / 2}) rotateX(-90deg);

        .cube-face--top {
          opacity: 1;
        }
      }
      &--bottom {
        transform: translateZ(-#{$cube-size / 2}) rotateX(90deg);

        .cube-face--bottom {
          opacity: 1;
        }
      }
    }

    .cube-face {
      position: absolute;
      width: $cube-size;
      height: $cube-size;
      text-align: center;
      line-height: 200px;
      opacity: 0;

      &--front {
        transform: rotateY(0deg) translateZ(#{$cube-size / 2});
      }
      &--back {
        transform: rotateY(180deg) translateZ(#{$cube-size / 2});
      }
      &--right {
        transform: rotateY(90deg) translateZ(#{$cube-size / 2});
      }
      &--left {
        transform: rotateY(-90deg) translateZ(#{$cube-size / 2});
      }
      &--top {
        transform: rotateX(90deg) translateZ(#{$cube-size / 2});
      }
      &--bottom {
        transform: rotateX(-90deg) translateZ(#{$cube-size / 2});
      }
    }
  }
}
