.error-page {
  height: 100vh;

  .error-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 15vh;
    margin: 0 auto;
    width: max-content;
    color: #32B9C1;
    font: 300 36px "Gothic A1";
  }

  .error-code {
    bottom: -4px;
    left: 0;
    right: 0;
    position: absolute;

    .code-img {
      width: 100%;
      max-height: 700px;
    }
  }
}
