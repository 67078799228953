@import "../global/css/colors";
@import "../global/css/fonts";
@import "../global/css/gaps";

.modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 400px;
	padding: 30px 0;
	border-radius: 15px;
	box-shadow: 0px 5px 15px 0px rgba($color-quat, 0.4);
	text-align: center;
	z-index: 1;
	@extend %paragraph-normal-text;
	color: $color-sec;
	opacity: 0.3;
	visibility: hidden;
	transition-duration: 0.1s;
	transition-timing-function: ease-in;
	transition-property: top, opacity;

	&.open {
		visibility: visible;
		top: 15px;
		opacity: 1;
	}

	&.success {
		background: $color-success;
	}

	&.error {
		background: $color-error;
	}

	.close {
		background: url('../global/img/close.svg');
		background-size: 100% 100%;
		width: 15px;
		height: 15px;
		position: absolute;
		top: 15px;
		right: 15px;
		content: ' ';
		cursor: pointer;
		opacity: 0.5;
	}
}

@media (max-width: $phone-screen) {
	.modal {
		width: 200px;
		padding: 40px 10px;

		&.open {
			top: 40px;
		}
	}
}
