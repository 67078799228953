.home {
	background: url('../global/img/3d-logo.png') no-repeat center center;
	background-size: cover;
	width: 100vw;
  height: 100vh;
}

/* Animations */

.home {
  transform: translateY(20%);
  opacity: 0;
  transition: all .3s;
}

.pr-appear-done, .pl-appear-done,
.pr-enter-done, .pl-enter-done,
.pr-exit-active, .pl-exit-active, {

  .home {
    transform: translateY(0);
    opacity: 1;
  }
}

.pr-enter-active, .pl-enter-active {

  .home {
    transform: translateY(0);
    opacity: 1;
    transition-delay: .3s;
  }
}

