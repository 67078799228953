@import "../global/css/colors";
@import "../global/css/fonts";
@import "../global/css/gaps";

.container {
  display: grid;
  grid-template-columns: repeat(12, $column-size);
  grid-column-gap: $column-gap;
  grid-row-gap: 0px;
  justify-content: center;
  user-select: none;
}

@for $i from 1 through 12 {
  @for $a from $i through 12 {
    .col-#{$i}-#{$a} {
      grid-column-start: #{$i};
      grid-column-end: #{$a+1};
    }
    .row-#{$i}-#{$a} {
      grid-row-start: #{$i};
      grid-row-end: #{$a+1};
    }
  }
}

@media (max-width: $tablet-screen) {
  .container {
    display: grid;
    grid-template-columns: repeat(12, $tablet-column-size);
    grid-column-gap: $tablet-column-gap;
    grid-row-gap: 0px;
    justify-content: center;
  }

  @for $i from 1 through 12 {
    @for $a from $i through 12 {
      .col-tb-#{$i}-#{$a} {
        grid-column-start: #{$i};
        grid-column-end: #{$a+1};
      }
      .row-tb-#{$i}-#{$a} {
        grid-row-start: #{$i};
        grid-row-end: #{$a+1};
      }
    }
  }
}

@media (max-width: $phone-screen) {
  .container {
    display: grid;
    grid-template-columns: repeat(12, minmax($phone-column-size, auto));
    grid-column-gap: $phone-column-gap;
    grid-row-gap: 0px;
    justify-content: center;
  }
}

::selection {
  color: #FFF;
  background-color: $color-pri;
}

.cozalac {

}

.page-section {

}

.menu-section {
  justify-self: center;
  align-self: end;
  margin: 0 0 30px 0;
  position: absolute;
  left: 0;
  bottom: 0;
}

.pr-appear-done, .pl-appear-done {
  .swipe-help {
    background: url('../global/img/swipe.svg') no-repeat center;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    animation: 3s silik infinite;
  }

  @keyframes silik {
    0% {
      opacity: .7;
    }
    50% {
      opacity: .3;
    }
    100% {
      opacity: .7;
    }
  }
}
