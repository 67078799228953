.pr {
  position: relative;
  width: 100%;
  transition: 1s transform cubic-bezier(.15,.39,.41,1);
}

.pr-enter {
  transform: translate3d(100%, 0, 0);
  position: fixed;
}

.pr-enter-active {
  transform: translate3d(0, 0, 0);
}

.pr-exit {
  transform: translate3d(0, 0, 0);
  position: fixed;
}

.pr-exit-active {
  transform: translate3d(-100%, 0, 0);
}

.pl {
  position: relative;
  width: 100%;
  transition: 1s transform cubic-bezier(.15,.39,.41,1);
}

.pl-enter {
  transform: translate3d(-100%, 0, 0);
  position: fixed;
}

.pl-enter-active {
  transform: translate3d(0, 0, 0);
}

.pl-exit {
  transform: translate3d(0, 0, 0);
  position: fixed;
}

.pl-exit-active {
  transform: translate3d(100%, 0, 0);
}
